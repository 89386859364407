<template>
  <b-modal
    v-model="conditionalCities"
    centered
    size="lg"
    title="Cities"
    ok-title="Ok"
    ok-only
    hide-footer
    no-close-on-backdrop
    @hide="onHide"
  >
    <ul class="city" >
      <li v-for="item in cities" :key="item.id">{{item.name}}</li>
    </ul>
  </b-modal>
</template>

<script>
import { BModal, BBadge } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BBadge
  },
  props: {
    cities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      conditionalCities: true,
    };
  },
  methods: {
    onHide() {
      this.$emit("cerrarModalCities", false);
    },
  },
};
</script>

<style scoped>
.city{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>