import { userAxios } from "../index";


/**
 * This function will return a promise that will resolve to the data returned from the API call.
 * @param data - {
 * @returns The return value of the async function is a promise.
 */
const stateList = async (perPage) => {
    try {
        return await userAxios.get(`states/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const stateFilterPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`states/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const stateFilterFilters = async (perPage, data) => {
    try {
        return await userAxios.post(`states-search`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
/**
 * It takes a page number and a data object and returns the data from the API call.
 * @param page - the page number
 * @param data - {
 * @returns The data is being returned from the backend.
 */
const stateFilterFiltersPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`states-search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const stateUpdate = async (id, data) => {
    try {
        return await userAxios.put(`states/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const stateCreate = async (data) => {
    try {
        return await userAxios.post('states', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    stateList,
    stateFilterPagination,
    stateFilterFilters,
    stateFilterFiltersPagination,
    stateUpdate,
    stateCreate
}