<template>
  <b-button @click="goBack" aria-label="goback" variant="warning" class="btn-icon rounded-circle">
    <feather-icon icon="CornerUpLeftIcon" size="18" />
  </b-button>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>