<template>
  <div></div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ToastificationContent,
  },
  methods: {
    danger(text = '', title = 'Error', icon = 'InfoIcon') {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'AlertOctagonIcon',
            text,
            variant: 'danger',
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    success(text = '', title = 'Success', icon = 'InfoIcon') {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'CheckIcon',
            text,
            variant: 'success',
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    warning(text = '', title = 'Warning', icon = 'InfoIcon') {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'AlertTriangleIcon',
            text,
            variant: 'warning',
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    ccNotification() {
      this.$swal({
        title: "IMPORTANT",
        text: "The convenience fee will be charged to Self pay patients who pay by credit or debit card. Patients with insurance will only be charged the convenience fee when paying by credit card. CONVENIENCE FEE WILL NEVER BE CHARGED FOR CASH PAYMENTS",
        icon: "warning",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
    }
  },
};
</script>