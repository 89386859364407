<template>
  <div>
    <StateListFilters
      v-if="$can('filter', 'module_system_settings.settings.admin_facility.states')"
      :statusOptions="statusOptions"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <ModalCities
      v-if="conditionalCities"
      :cities="itemCities"
      @cerrarModalCities="cerrarModalCities"
    />
    <b-card>
      <TitleTable titleTable="States" iconTable="MapIcon" />
      <TableStateAdd
        :is-add-new-state-sidebar-active.sync="isAddNewStateSidebarActive"
        @createState="createState"
      />
      <TableStateEdit
        v-if="isEditStateSidebarActive"
        :is-edit-state-sidebar-active.sync="isEditStateSidebarActive"
        :itemEdit="itemEdit"
        @isEditState="isEditState"
      />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>

          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                @click="isAddNewStateSidebarActive = true"
                v-if="$can('store', 'module_system_settings.settings.admin_facility.states')"
              >
                <span class="text-nowrap">{{ $t("Add") }} State</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        outlined
        bordered
        class="position-relative"
        responsive
        hover
        :items="items"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-asc.sync="isSortDirDesc"
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Edit'"
            @click="editState(data.item)"
            v-if="$can('update', 'module_system_settings.settings.admin_facility.states')"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.top="'Change Status'"
            variant="flat-warning"
            class="btn-icon"
            v-if="$can('update', 'module_system_settings.settings.admin_facility.states')"
            @click="changeState(data.item)"
          >
            <feather-icon icon="RefreshCcwIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.top="'View cities'"
            variant="flat-primary"
            class="btn-icon"
            @click="viewCities(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";

import ModalCities from "./ModalCities.vue";
import TableStateAdd from "./TableStateAdd.vue";
import TableStateEdit from "./TableStateEdit.vue";
import StateListFilters from "./StateListFilters.vue";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import axiosS from "@/services/admin/state";

export default {
  components: {
    BTable,
    BCard,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BPagination,
    vSelect,
    ToastNotification,
    TableStateAdd,
    TableStateEdit,
    StateListFilters,
    TitleTable,
    ModalCities,
    GoBack,
    PaginationTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted() {
    this.getStates();
  },
  data() {
    return {
      tableColumns: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "status", sortable: true },
        { key: "actions" },
      ],
      items: [],
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "id",
      isSortDirDesc: true,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isAddNewStateSidebarActive: false,
      isEditStateSidebarActive: false,
      statusOptions: [
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false" },
      ],
      itemEdit: null,
      itemCities: [],
      conditionalCities: false,
      filtro: false,
      stateFilter: null,
      loading: false,
    };
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getStates(newVal);
      } else {
        this.searchFilter(newVal, this.stateFilter);
      }
    },
  },
  methods: {
    filterPage(value) {
      this.filtro = value;
    },
    getStates() {
      axiosS
        .stateList(this.perPage)
        .then(({ data, total, current_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosS
          .stateFilterPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosS
          .stateFilterFiltersPagination(this.perPage, page, this.stateFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    createState(value) {
      if (value) {
        this.$refs.toast.success("State added successfully");
        this.isAddNewStateSidebarActive = false;
      } else {
        this.$refs.toast.danger("State not added");
        this.isAddNewStateSidebarActive = false;
      }
      this.pageChanged(this.currentPage.page);
    },
    editState(item) {
      this.itemEdit = item;
      this.isEditStateSidebarActive = true;
    },
    isEditState(value) {
      if (value) {
        this.$refs.toast.success("State updated successfully");
        this.isEditStateSidebarActive = false;
      } else {
        this.$refs.toast.danger("State not updated");
        this.isEditStateSidebarActive = false;
      }
      this.pageChanged(this.currentPage.page);
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.stateFilter = value;
        axiosS
          .stateFilterFilters(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getStates();
      }
    },
    changeState(item) {
      axiosS
        .stateUpdate(item.id, { ...item, status: !item.status })
        .then(() => {
          this.$refs.toast.success("State changed successfully");
        })
        .catch(() => {
          this.$refs.toast.danger("State not changed");
        });
      this.pageChanged(this.currentPage.page);
    },
    viewCities(item) {
      this.conditionalCities = true;
      this.itemCities = item.cities;
    },
    cerrarModalCities(value) {
      this.conditionalCities = value;
    },
  },
};
</script>
